import { Component, OnInit } from '@angular/core';
import { ComingSoonMessageComponent } from 'src/app/dialogs/coming-soon-message/coming-soon-message.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  public openComingSoon(){
    const dialogRef = this.matDialog.open(ComingSoonMessageComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

}
