import { Component, OnInit } from '@angular/core';
import { DocumentsService } from './documents.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { EnterOrganizationComponent } from 'src/app/components/enter-organization/enter-organization.component';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  public organization_UUID: string = '';
  public documents: any;
  public documentsArray: any;

  public search: string = '';
  public isLoading: boolean = false;

  constructor(
    private documentsService: DocumentsService,
    private router: Router,
    private route: ActivatedRoute,
    public matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams['uuid']) {
      this.organization_UUID = this.route.snapshot.queryParams['uuid'];
    } else {
      this.noOrganization();
    }
    //this.organization_UUID = this.router.url.split('/')[2];
    this.getDocumentsPublic();
  }

  public async getDocumentsPublic() {
    try {
      this.isLoading = true;
      const response: any = await this.documentsService.getPDFFormats(this.organization_UUID);
      if (response.status === 'ok') {
        this.documents = response.result;
        this.documentsArray = response.result;
        this.isLoading = false;
      }

    } catch (error) {
      this.isLoading = false;
    }
  }

  public async downloadDocument(document: any) {
    try {
      const response: any = await this.documentsService.downloadDocument(document.key_aws);
      if (response.status === 'ok') {
        window.open(response.results, '_blank');
      }
    } catch (error) {
    }
  }

  public searchDocument(event: any) {

    if (event.length >= 3) {
      var documents = this.documents;

      var search = this.documentsArray.filter((item: { data: any; }) =>
        item.data.toLowerCase().includes(event.toLowerCase())
      );
      this.documents = search;
    } else {
      this.getDocumentsPublic();

    }

  }

  public noOrganization() {
    const dialogRef = this.matDialog.open(EnterOrganizationComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (this.route.snapshot.queryParams['uuid']) {

        this.organization_UUID = this.route.snapshot.queryParams['uuid'];
        this.getDocumentsPublic();
      } else {
        if (this.router.url === '/documents') {
          this.noOrganization();
        }
      }
    });
  }

}
